.create-new-comment-container {
    position: fixed;
    right: calc(50vw - 640px);
    bottom: 0;
    height: 30rem;
    max-height: 100vh;
    width: 60rem;
    text-align: left;
    z-index: 5002;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 0px 24px 3px rgba(0,0,0,1);
}

.create-new-comment-container-inner {
    flex-direction: column;
    display: flex;
    padding: 0.8rem;
    padding-bottom: 0;
    height: inherit;
    width: inherit;
    /* border: 2px solid #666; */
    border-bottom: none;
    background-color: #272731;
    border-radius: 4px 4px 0 0;
}

@media (max-width: 1400px) {
    .create-new-comment-container {
        right: calc(50vw - 550px);
        width: 50rem;
    }

    .create-new-comment-container-inner {
        width: 50rem;
    }
}

@media (max-width: 1200px) {
    .create-new-comment-container {
        right: 2rem;
        width: 48rem;
    }

    .create-new-comment-container-inner {
        width: 48rem;
    }
}

@media (max-width: 768px) {

    .create-new-comment-container {
        width: 20rem;
    }

    .create-new-comment-container-inner {
        width: 20rem;
    }
    
}

.close-button:hover {
    background-color: #bfbfdc;
    transition: all 0.1s;
}

.create-comment-button {
    background-color: #272731;
    color: #e8e8e8;
    border: 0px solid transparent;
    margin-right: 8px;
    border-radius: 3px;
    cursor: pointer;
    opacity: 0.7;
    padding: 3px;
    height: 2rem;
    width: 2rem;
}

.create-comment-button:hover {
    background-color: #43434f;
    transition: all 0.1s;
}

.create-comment-top {
    border-bottom: 1px solid #4d4b57;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    height: 2rem;
    align-items: center;
}

.create-comment-mid {
    border-bottom: 1px solid #4d4b57;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.create-comment-reply-text {
    color: #e8e8e8;
}

.create-comment-post-title {
    color: #9179ce;
    font-family: "Arvo";
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1.1rem;
    max-height: 3.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.create-comment-user-id {
    color: #717171;
}

.create-comment-username {
    padding-left: 8px;
    color: #9179ce;
}

.create-comment-username:before {
    border-left: 0.5px solid #4d4b57;
    content: "";
    padding-left: 8px;
}

.create-comment-quote {
    color: #717171;
    overflow: auto;
    max-height: 6rem;
    margin-bottom: 0.5rem;
}

.create-comment-category-text {
    color: #9179ce;
    font-family: "Arvo";
    font-size: 1.1rem;
}