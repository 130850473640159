.reset-password-text {
    color: #e8e8e8;
}

.reset-password-code-input {
    color: #e8e8e8;
    background-color:rgb(21, 21, 21);
    width:100%;
    margin-top: 0.5rem;
    margin-bottom: 0;
    border-radius: 4px;
    border:1px solid transparent;
    padding: 0px 8px 0px;
}

.reset-password-password-input {
    color: #e8e8e8;
    background-color:rgb(21, 21, 21);
    width:100%;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border:1px solid transparent;
    padding: 0px 8px 0px;

}

.reset-password-email-input, .reset-password-confirm-password-input {
    color: #e8e8e8;
    background-color:rgb(21, 21, 21);
    width:100%;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    border-radius: 4px;
    border:1px solid transparent;
    padding: 0px 8px 0px;
}

.reset-password-email-input:focus, .reset-password-code-input:focus, .reset-password-password-input:focus, .reset-password-confirm-password-input:focus {
    border: 1px solid #635985;
    box-shadow: 0 0 10px #635985;
    outline: none;
}


.verify-code-button, .reset-password-button {
    height: 100%;
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    background-color: #BCADE1;
    color: #000;
    border: 1px solid transparent;
    border-radius: 5px;
    will-change: opacity;
}

.verify-code-button:hover, .reset-password-button:hover {
    opacity: 0.8;
    transition: all 0.4s;
}

.send-new-code-button {
    padding: 0 4px;
    font-size: 0.8rem;
    text-decoration: none;
    color: #999;
    border: 0px solid transparent;
    background-color: transparent;
    margin-bottom: 1rem;
}

.send-new-code-button:hover {
    color: #9179ce;
}