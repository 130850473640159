.comment-count-button {
    height: 100%;
    flex: 1;
    padding: 4px 8px !important;
    margin: 0 !important;
    background-color: #2d2d2d;
    color: #e8e8e8;
    border: 1px solid transparent;
    border-radius: 5px;
    opacity: 0.9;
}

.comment-count-button:hover {
    color: #9179ce;
    transition: all 0.2s;
}

.comment-count-number {
    padding-left: 4px;
    display: inline;
    font-size: 0.8em;
}