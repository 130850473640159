.content-overflow {
    overflow-y: auto;
    overflow-x: hidden;
    height: inherit;
}

.content-height {
    height: calc(100vh - 49px);
}

.content-div {
    /* background-color: #1d1928; */
    background-color: #201c2c;
    margin-bottom: 8px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 8px;
}

.content-div.highlighted {
    background-color: #2d283a;
}

.content-info {
    padding-bottom: 1rem;
}

.content-number-div, .content-date-div {
    color: #717171;
}

.content-username-div {
    color: #9179ce;
    cursor: pointer;
}

.content-username-div:before, .content-date-div:before, .content-star-div:before, .content-reply-div:before, .content-hide-div:before {
    border-left: 0.5px solid #4d4b57;
    content: "";
    padding-left: 8px;
}

.content-number-div, .content-username-div, .content-date-div, .content-star-div, .content-reply-div, .content-hide-div {
    padding-right: 8px;
}

.content-hide-div {
    flex: 1;
}

.content-comment-div {
    font-size: 1.2rem;
    padding-bottom: 1rem;
    white-space-collapse: preserve;
    word-break: break-word;
}

.content-vote-div {
    padding-bottom: 1rem;
}

.content-comment-count{
    margin-left: 0.5rem;
    padding-bottom: 1rem;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    height: 100%;
}

.post-not-available-div {
    text-align: center;
    font-size: 2rem;
    color: #9179ce;
}


.not-found-div {
    text-align: center;
    font-size: 2rem;
    color: #9179ce;
}

.content-col .content-tooltip {
    background-color: #e8e8e8;
    color: #333;
    padding: 0.2rem 1rem;
    font-size: 0.9rem;
    max-width: 25%;
    z-index: 5000;
    transition: opacity 0.1s ease-out;
}

.page-number-div {
    display:flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
}

.page-number-inner-div {
    position: relative;
}

.page-number-text {
    color: #9179ce;
    font-size: 1.3rem;
    text-align: center;
}

.share-button {
    background-color: #2d2d39;
    border: none;
    color: #fff;
    opacity: 0.7;
    height: 3rem;
    width: 2.5rem;
}

.share-button:hover {
    background-color: #43434f;
    transition: all 0.1s;
    border-radius: 3px;
}

.share-popover {
    color: #e8e8e8;
    background-color: #43434f;
    border-radius: 5px;
    box-shadow: 0 0px 24px 3px rgba(0,0,0,1);
    max-width: 450px;
    margin-top: 6px;
}

.share-popover > .popover-arrow:after {
    border-bottom-color: #43434f !important;
}

.share-popover-body {
    color: #e8e8e8;
    display: flex;
    padding: 8px;
}

.popover-arrow-container {
    display: flex;
}

.share-text-area {
    color: #e8e8e8;
    background-color: rgb(20, 20, 20);
    resize: none;
    width: 450px;
    height: 75px;
    border-radius: 3px;
    padding: 10px;
}

@media (max-width: 550px) {
    .share-text-area {
        width: 200px;
    }
}

.share-copy-div {
    margin-left: 5px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 75px;
    border-radius: 3px;
}

.share-copy-div:hover {
    cursor: pointer;
    background-color: #575762;
    transition: all 0.1s;
    border-radius: 3px;
}

.content-star-button, .show-hidden-button, .content-hide-button, .content-share-button {
    padding: 0;
    background-color: inherit;
    border: none;
    color: #fff;
    opacity: 0.7;
}

.content-star-button:hover, .show-hidden-button:hover, .content-hide-button:hover, .content-share-button:hover {
    color: #9179ce;
    transition: all 0.1s;
}

.page-button-div {
    position: fixed;
    right: calc(50vw - 620px);
    top: 6rem;
}

.page-button-inner-div {
    position: relative;
    height: 3rem;
    width: 3rem;
    line-height: 3rem;
    font-size: 1.3rem;
    text-align: center;
    background-color: #2d2d39;
    color: #dedede;
    border-radius: 50%;
    opacity: 0.9;
}

.page-button-inner-div:hover {
    color: #9179ce;
    opacity: 1;
}

.page-button-select {
    font-size: 1rem;
}

.page-button-select, .page-select {
    background-color: #141418;
    border: none;
    cursor: pointer;
    color: #9179ce;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    left: 0;
}

.page-select:focus {
    border:none;
}

.load-previous-page-div {
    background-color: #201c2c;
    text-align: center;
    margin-bottom: 1rem;
    line-height:80px;
    cursor: pointer;
    color: #e8e8e8;
}

.load-previous-page:hover {
    background-color: #43434f3e;
    transition: all 0.2s;
}

.content-placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    background-color: #717171;
    opacity: .5;
    -webkit-mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,0.8) 75%,#000 95%);
    mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,0.8) 75%,#000 95%);
    -webkit-mask-size: 200% 100%;
    mask-size: 200% 100%;
    animation: content-placeholder-wave 1s linear infinite;
    border-radius: 2px;
}

@keyframes content-placeholder-wave {
    100% {
        -webkit-mask-position: -200% 0%;
        mask-position: -200% 0%;
    }
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.content-header {
    background-color: #2d2d39;
    padding: 0;
    border-bottom: 1px solid #4d4b57;
    height: 49px;
    padding-left: 12px;
}

.content-header-title-wrapper {
    display:flex;
    align-items:center;
}

.content-header-title {
    font-size: 1.3rem;
    color: #9281bd;
    font-family: "Arvo";
    text-overflow: ellipsis;
    overflow: hidden; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.4rem;
    margin: 0;
    max-height: 47px;
}