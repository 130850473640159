.Button__root {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: 0px;
    background-color: #3c3c49;
    color: #e8e8e8;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}
.Button__root:hover {
    background-color: #43434f;
}
.Button__small {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
}
.Button__disabled {
    cursor: not-allowed;
}
.Button__disabled:hover {
    background-color: #43434f;
}