.popup {
    background-color: #2d2d39;
    width: 300px;
    min-height: 5rem;
    max-height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s ease-out;
    z-index: 9999;
    margin-bottom: 2rem;
    will-change: transform, opacity;
    border-radius: 5px;
}

@keyframes slideFadeIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

.popup.slideIn {
    animation-name: slideFadeIn;
    animation-duration: 0.2s;
    animation-timing-function: ease-in-out;
}

.popup.slideOut {
    transform: translateX(150%);
    opacity: 0;
}

.popup-header {
    background-color: #2d2d39;
    flex: 1;
    padding-left: 1rem;
}

.popup-body {
    background-color: #272731;
    flex: 2;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 8px;
    padding-bottom: 8px;
    overflow: auto;
    word-break: break-word;
}

.popup-title {
    flex: 1;
    padding-top: 4px;
}

.popup-close-button {
    background-color: #2d2d39;
    border: none;
    color: #fff;
    border-top-right-radius: 5px;
    height: 34px;
}

.popup-close-button:hover {
    background-color: #43434f;
    transition: all 0.1s;
}