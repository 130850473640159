.refresh-comment-div {
    background-color: #201c2c;
    text-align: center;
    margin-bottom: 1rem;
    line-height:80px;
}

.refresh-comment {
    cursor: pointer;
    color: #e8e8e8;
}

.refresh-comment:hover {
    background-color: #43434f3e;
    transition: all 0.2s;
}