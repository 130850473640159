.privacy {
    padding-top: 16px;
    padding-left: 16px;
}

.privacy h1 {
    font-size: 1.5rem;
}

.privacy > ul {
    list-style: decimal;
    padding-left: 2rem;
}

.privacy > ul ::marker {
    font-size: 1.5rem;
}

.privacy > ul > li > ul {
    list-style: disc;
    padding-left: 1.5rem;
}

.privacy > ul > li > ul ::marker {
    font-size: 1rem;
}

.privacy a {
    color: white;
}