.create-new-post-container {
    position: fixed;
    right: calc(50vw - 655px);
    bottom: 0;
    height: 35rem;
    max-height: 100vh;
    width: 62rem;
    text-align: left;
    z-index: 5001;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 0px 24px 3px rgba(0,0,0,1);
}

.create-new-post-container-inner {
    display: flex;
    flex-direction: column;
    height: inherit;
    width: inherit;
    padding: 0.8rem;
    padding-bottom: 2px;
    background-color: #272731;
    /* border: 2px solid #666; */
    border-bottom: none;
    border-radius: 4px 4px 0 0;
}

@media (max-width: 1400px) {
    .create-new-post-container {
        right: calc(50vw - 550px);
        width: 52rem;
    }

    .create-new-post-container-inner {
        width: 52rem;
    }
}

@media (max-width: 1200px) {
    .create-new-post-container {
        right: 1rem;
        width: 50rem;
    }

    .create-new-post-container-inner {
        width: 50rem;
    }
}

@media (max-width: 768px) {
    .create-new-post-container {
        width: 22rem;
    }

    .create-new-post-container-inner {
        width: 22rem;
    }
}

.create-post-top {
    display:flex;
    height: 2rem;
    align-items: center;
    border-bottom: 1px solid #4d4b57;
    padding-bottom: 0.5rem;
}

.form-text-color {
    color: #e8e8e8;
}

.create-topic-input {
    color: #e8e8e8;
    background-color:rgb(21, 21, 21);
    width:100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 6px;
    border:1px solid transparent;
    padding: 0px 8px 0px;
}

.create-topic-input:focus {
    outline: none;
}

.create-topic-category-text {
    color: #9179ce;
    font-family: "Arvo";
    font-size: 1.1rem;
}

.create-category-dropdown {
    background-color:rgb(21, 21, 21);
    color: #e8e8e8;
    border: none;
    border-radius: 6px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
}

.create-category-dropdown:focus {
    outline: none;
}

.create-content-input {
    color: #e8e8e8;
    background-color:rgb(20, 20, 20);
    width:100%;
    height:78%;
    border-radius: 6px;
    border:1px solid transparent;
    padding: 0px 8px 0px;
    resize: none;
}

.create-content-input:focus {
    outline: none;
}

.create-button-div {
    width: 100%;
}

.create-post-button {
    background-color: #272731;
    color: #e8e8e8;
    border: 0px solid transparent;
    margin-right: 8px;
    border-radius: 3px;
    cursor: pointer;
    opacity: 0.7;
    padding: 3px;
    height: 2rem;
    width: 2rem;
}

.create-post-button:hover {
    background-color: #43434f;
    transition: all 0.1s;
}

.close-button-div {
    background-color: #272731;
    color: #e8e8e8;
    cursor: pointer;
    opacity: 0.7;
    padding: 3px;
    height: 2rem;
    width: 2rem;
    border-radius: 3px;
}

.close-button-div:hover {
    background-color: #43434f;
    transition: all 0.1s;
}