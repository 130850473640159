.editor-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;
}

.editor-inner {
    position: relative;
    flex: 1 1;
    max-height: calc(100% - 3rem);
}

.editor-input {
  color: #e8e8e8;
  background-color: rgb(20, 20, 20);
  width: 100%;
  border-radius: 0 0 6px 6px;
  border: 1px solid transparent;
  padding: 8px;
  padding-bottom: 50px;
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  position: absolute;
}

.editor-input:focus {
  outline: none;
}

.editor-paragraph {
  margin-bottom: 0;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-link {
  color: #1b77ff;
  text-decoration: auto;
}

.editor-link:hover {
  color: #1b77ff;
  text-decoration: underline;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
  list-style: disc;
}

.editor-quote {
  margin-left: 16px;
  font-size: 0.9rem;
  color: #717171;
  border-left-color: #717171;
  border-left-width: 2px;
  border-left-style: solid;
  padding-left: 8px;
}

.editor-listitem {
  margin: 8px 32px 8px 20px;
}

.editor-nested-listitem {
  list-style-type: none;
}

.editor-embedBlock {
  user-select: none;
}

.editor-embedBlockFocus {
  outline: 2px solid rgb(60, 132, 244);
}

.typeahead-popover {
  background: #201c2c;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 1);
  border-radius: 8px;
  position: fixed;
  z-index: 5003;
}

.typeahead-popover ul {
  padding: 0;
  list-style: none;
  margin: 0;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
}

.typeahead-popover ul::-webkit-scrollbar {
  display: none;
}

.typeahead-popover ul {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.typeahead-popover ul li {
  margin: 0;
  min-width: 180px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
}

.typeahead-popover ul li.selected {
  color: #9179ce;
}

.typeahead-popover li {
  margin: 0 8px 0 8px;
  padding: 8px;
  color: #e8e8e8;
  cursor: pointer;
  line-height: 16px;
  font-size: 15px;
  display: flex;
  align-content: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: #201c2c;
  border-radius: 8px;
  border: 0;
}

.typeahead-popover li.active {
  display: flex;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.typeahead-popover li:first-child {
  border-radius: 8px 8px 0px 0px;
}

.typeahead-popover li:last-child {
  border-radius: 0px 0px 8px 8px;
}

.typeahead-popover li:hover {
  color: #9179ce;
}

.typeahead-popover li .text {
  display: flex;
  line-height: 20px;
  flex-grow: 1;
  min-width: 150px;
}

.typeahead-popover li .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.auto-embed-menu {
  width: 150px;
}

.editor-image .image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: #9179ce;
  border: 1px solid #fff;
}

.editor-image .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.editor-image .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.editor-image .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.editor-image .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

.editor-image .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.editor-image .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

.editor-image .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.editor-image .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}

span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}

.editor-image img {
  max-width: 100%;
  cursor: default;
  margin-bottom: 5px;
}

.editor-image img.focused {
  outline: 2px solid #9179ce;
  user-select: none;
}

.editor-image img.focused.draggable {
  cursor: grab;
}

.editor-image img.focused.draggable:active {
  cursor: grabbing;
}