.topic-more-button {
    background-color: #2d2d39;
    border: none;
    color: #fff;
    height: 3rem;
    width: 2.5rem;
    z-index:1;
    position: relative;
}

.topic-more-button:hover {
    background-color: #43434f;
    transition: all 0.1s;
}

.topic-more-popover-body {
    color: #e8e8e8;
    display: flex;
    padding: 8px 4px;
    flex-direction: column;
}

.topic-more-popover > .popover-arrow:after {
    border-bottom-color: #2d2d39 !important;
}

.topic-more-popover {
    color: #e8e8e8;
    background-color: #2d2d39;
    border-radius: 5px;
    box-shadow: 0 0px 24px 3px rgba(0,0,0,1);
    max-width: 450px;
}

.popover-text {
    padding-left: 8px;
}

.read-notifications-button, .delete-notifications-button, .delete-bookmarks-button{
    background-color: #2d2d39;
    border: none;
    color: #fff;
    opacity: 0.7;
    height: 3rem;
}

.read-notifications-button:hover, .delete-notifications-button:hover, .delete-bookmarks-button:hover {
    background-color: #43434f;
    transition: all 0.1s;
    border-radius: 3px;
}